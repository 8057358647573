import { BehaviorSubject } from 'rxjs';
import CfaPerms from '../types/CfaPerms';

export interface UserPermissionsSubject {
  cfaPerms: CfaPerms | undefined;
  cfaLocList: string[];
  hasKPSAccess: boolean;
  hasMenuAccess: boolean;
  hasCARESAccess: boolean;
  hasDeliveryAccess: boolean;
  hasDOPAccess: boolean;
  hasPOSAccess: boolean;
  hasStoreListingAccess: boolean;
  hasVenuesAccess: boolean;
  hasViewPointAccess: boolean;
  hasTimePunchAccess: boolean;
}

const userPermissions = new BehaviorSubject<UserPermissionsSubject>({
  cfaPerms: undefined,
  cfaLocList: [],
  hasKPSAccess: false,
  hasMenuAccess: false,
  hasCARESAccess: false,
  hasDeliveryAccess: false,
  hasDOPAccess: false,
  hasPOSAccess: false,
  hasStoreListingAccess: false,
  hasVenuesAccess: false,
  hasViewPointAccess: false,
  hasTimePunchAccess: false,
});

export default userPermissions;
